<template>
	<v-layout align-space-between column fill-height w-100>
		<v-flex bg-gray-ultra-dark shrink text-xs-right>
			<v-btn
                  flat
                  round
                  class="btn-white px-4 my-3 fw-400"
                  
                >
				<Icon name="fal fa-print" size="16" class="mr-2"  />
				<a href="javascript:;" onclick="window.print()" class="print">Imprimir</a>
				</v-btn>
				<!-- <v-btn
                  flat
                  round
                  class="btn-white px-4 my-3 mr-3 fw-400"
                  
                >
				<Icon name="fal fa-share-alt" size="16" class="mr-2"  />
				Compartilhar</v-btn> -->
		</v-flex>
		<v-flex d-flex fill-height>
			<iframe :src="getConsentTermUrl()" frameborder="0" width="100%" height="100%" scrolling="auto"></iframe>
		</v-flex>
	</v-layout>
</template>
<script type="plain/text">
export default {
  methods:{
	  getConsentTermUrl(){
		  var cartId = window.location.href.split("?")[1];
		  var consentTermUrl = "/termo?" + cartId;
		  console.log(consentTermUrl);
		  return consentTermUrl;
	  }
  }
};
</script>
<style scoped>
body{
	overflow:hidden
}
</style>